<script>
  import { Router } from "@roxi/routify";
  import { routes } from "../.routify/routes";
</script>

<style  global>:global(:root) {
	--bg-color: #4B0082;
}

:global(body) {
	color: #ffffff;
	margin: 0;
	box-sizing: border-box;
	font-family: "Segoe UI", "Helvetica Neue", sans-serif;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;

	background-color: var(--bg-color)
}

:global(.background-stripes) {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background: repeating-linear-gradient(
		-45deg,
		transparent,
		transparent 160px,
		rgba(0, 0, 0, 0.1) 160px,
		rgba(0, 0, 0, 0.1) 320px
	);
	z-index: -100;
}

/* Center Classes */

:global(.center-text) {
	text-align: center;
}

:global(.center) {    
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

:global(.center-bottom) {    
	left: 50%;
	bottom: 0px;
	transform: translate(-50%, -50%);
}

:global(.flex-center) {
	display: flex;
	justify-content: center;
}

:global(.flex-center-col) {
	display: flex;
	flex-direction: column;
    align-items: center;
}

:global(#routify-app) {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

:global(.dropshadow) {
	filter: drop-shadow(2px 4px 6px black);
}

/* Center Classes */

:global(a) {
	color: rgb(0,100,200);
	text-decoration: none;
}

:global(a:hover) {
	text-decoration: underline;
}

:global(a:visited) {
	color: rgb(0,80,160);
}

:global(label) {
	display: block;
}

:global(input), :global(button), :global(select), :global(textarea) {
	font-family: inherit;
	font-size: inherit;
	padding: 0.4em;
	margin: 0 0 0.5em 0;
	box-sizing: border-box;
	border: 1px solid #ccc;
	border-radius: 2px;
}

:global(input:disabled) {
	color: #ccc;
}

:global(input[type="range"]) {
	height: 0;
}

:global(button) {
	color: #333;
	background-color: #f4f4f4;
	outline: none;
}

:global(.btn-play) {
	color: #ffffff;
	background-color: #009d00;
}

:global(.btn-stop) {
	color: #ffffff;
	background-color: darkred;
}

:global(.fancy-btn) {
	/* Text Styling */
	font-size: 1.5rem;

	/* Button Styling */
	box-shadow: 2px 2px 5px black;
	border-radius: 3px;
	border: none;
	width: 15rem;
	
	user-select: none;
	transition: all .2s ease-in-out;
}

:global(.fancy-btn:not(:disabled):not(:active):hover) {
		transform: scale(1.025);
		filter: brightness(115%);
	}

:global(.fancy-btn:disabled) {
		filter: brightness(75%);
		transform: scale(.90);
	}

:global(.fancy-btn:active) {
		filter: brightness(90%);
		transform: scale(.95);
	}

:global(img.emoji) {
	height: 1em;
	width: 1em;
	margin: 0 .05em 0 .1em;
	vertical-align: -0.1em;

	user-select: none;
}

:global(img.emoji-fill) {
	width: 100%;
	height: 100%;

	user-select: none;
}</style>

<Router {routes} />
